import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { saveAs } from 'file-saver';
import LoadingSkeleton from './LoadingSkeleton';

// Register Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: (context) => context.theme === 'dark' ? '#fff' : '#666'
      }
    },
    tooltip: {
      callbacks: {
        label: (context) => `$${context.raw.toLocaleString()}`
      }
    }
  }
};

const GameRevenueCalculator = () => {
  const [price, setPrice] = useState('');
  const [numReviews, setNumReviews] = useState('');
  const [platform, setPlatform] = useState('steam');
  const [estimated, setEstimated] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [breakdown, setBreakdown] = useState(null);
  const [conversionFactor, setConversionFactor] = useState(50);
  const [errors, setErrors] = useState({});
  const [darkMode, setDarkMode] = useState(false);
  const [history, setHistory] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    // Load saved values from localStorage
    const savedValues = localStorage.getItem('calculatorValues');
    if (savedValues) {
      const { price, numReviews, platform, conversionFactor: savedFactor } = JSON.parse(savedValues);
      setPrice(price);
      setNumReviews(numReviews);
      setPlatform(platform);
      setConversionFactor(savedFactor);
    }

    // Load dark mode preference
    const isDark = localStorage.getItem('darkMode') === 'true';
    setDarkMode(isDark);
    document.body.classList.toggle('dark', isDark);

    // Load calculation history
    const savedHistory = localStorage.getItem('calculationHistory');
    if (savedHistory) {
      setHistory(JSON.parse(savedHistory));
    }
  }, []);

  const saveToLocalStorage = (values) => {
    localStorage.setItem('calculatorValues', JSON.stringify(values));
  };

  const storeCuts = {
    'steam': { cut: 0.30, name: 'Steam' },
    'epic': { cut: 0.12, name: 'Epic Games Store' },
    'gog': { cut: 0.30, name: 'GOG' }
  };

  const validateInputs = () => {
    const newErrors = {};
    if (!price) newErrors.price = 'Price is required';
    else if (isNaN(price) || Number(price) < 0) newErrors.price = 'Price must be a positive number';
    else if (Number(price) > 1000) newErrors.price = 'Price seems unusually high';
    
    if (!numReviews) newErrors.numReviews = 'Number of reviews is required';
    else if (isNaN(numReviews) || Number(numReviews) < 0) newErrors.numReviews = 'Reviews must be a positive number';
    else if (Number(numReviews) > 1000000) newErrors.numReviews = 'Review count seems unusually high';
    
    if (conversionFactor < 1) newErrors.conversionFactor = 'Factor must be at least 1';
    else if (conversionFactor > 200) newErrors.conversionFactor = 'Factor seems unusually high';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    document.body.classList.toggle('dark', newMode);
    localStorage.setItem('darkMode', newMode);
  };

  const exportData = () => {
    const data = {
      currentCalculation: {
        price,
        numReviews,
        platform,
        conversionFactor,
        result: breakdown
      },
      history
    };
    const blob = new Blob([JSON.stringify(data, null, 2)], {type: 'application/json'});
    saveAs(blob, 'revenue-calculations.json');
  };

  const saveToHistory = (result) => {
    const newEntry = {
      timestamp: new Date().toISOString(),
      inputs: { price, numReviews, platform, conversionFactor },
      result
    };
    const updatedHistory = [newEntry, ...history].slice(0, 10); // Keep last 10 calculations
    setHistory(updatedHistory);
    localStorage.setItem('calculationHistory', JSON.stringify(updatedHistory));
  };

  const calculateRevenue = () => {
    if (!validateInputs()) return;

    setIsLoading(true);
    setEstimated(null);
    setBreakdown(null);

    // Save current values
    saveToLocalStorage({ price, numReviews, platform, conversionFactor });

    // Simulate API delay
    setTimeout(() => {
      const estimatedSales = numReviews * conversionFactor;
      const platformCut = storeCuts[platform].cut;
      const refundRate = 0.05;
      const regionalPriceImpact = 0.80;
      
      const grossRevenue = estimatedSales * Number(price);
      const afterPlatformCut = grossRevenue * (1 - platformCut);
      const afterRefunds = afterPlatformCut * (1 - refundRate);
      const finalRevenue = afterRefunds * regionalPriceImpact;

      setBreakdown({
        grossRevenue,
        platformRevenue: grossRevenue - afterPlatformCut,
        refundLoss: afterPlatformCut - afterRefunds,
        regionalImpact: afterRefunds - finalRevenue,
        finalRevenue
      });

      setEstimated(parseFloat(finalRevenue).toFixed(2));
      setIsLoading(false);
      saveToHistory({
        grossRevenue,
        platformRevenue: grossRevenue - afterPlatformCut,
        refundLoss: afterPlatformCut - afterRefunds,
        regionalImpact: afterRefunds - finalRevenue,
        finalRevenue
      });
    }, 500);
  };

  const resetCalculator = () => {
    setPrice('');
    setNumReviews('');
    setPlatform('steam');
    setConversionFactor(50);
    setEstimated(null);
    setBreakdown(null);
    setErrors({});
    localStorage.removeItem('calculatorValues');
  };

  const chartData = useMemo(() => 
    breakdown ? {
      labels: ['Gross Revenue', 'Platform Fee', 'Refund Loss', 'Regional Impact', 'Final Revenue'],
      datasets: [{
        data: [
          breakdown.grossRevenue,
          breakdown.platformRevenue,
          breakdown.refundLoss,
          breakdown.regionalImpact,
          breakdown.finalRevenue
        ],
        backgroundColor: darkMode ? 
          ['#6ee7b7', '#fca5a5', '#fcd34d', '#93c5fd', '#c4b5fd'] :
          ['#4ade80', '#f87171', '#fbbf24', '#60a5fa', '#a78bfa']
      }]
    } : null,
    [breakdown, darkMode]
  );

  return (
    <div className={`calculator-container ${darkMode ? 'dark' : ''}`}>
      <div className="header-controls">
        <h2>Game Revenue Calculator</h2>
        <button onClick={toggleDarkMode} className="mode-toggle" aria-label="Toggle dark mode">
          {darkMode ? '🌞' : '🌙'}
        </button>
      </div>
      
      {isLoading ? (
        <LoadingSkeleton lines={4} />
      ) : (
        <>
          <div className="form-group">
            <label>Platform</label>
            <select
              value={platform}
              onChange={(e) => setPlatform(e.target.value)}
            >
              <option value="steam">Steam</option>
              <option value="epic">Epic Games Store</option>
              <option value="gog">GOG</option>
            </select>
          </div>

          <div className={`form-group ${errors.price ? 'error' : ''}`}>
            <label htmlFor="price" id="price-label">
              Game Price
              <span className="required" aria-hidden="true">*</span>
            </label>
            <input
              type="number"
              id="price"
              name="price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              aria-labelledby="price-label"
              aria-describedby={errors.price ? 'price-error' : undefined}
              aria-invalid={errors.price ? 'true' : 'false'}
            />
            {errors.price && (
              <div className="error-message" id="price-error" role="alert">
                <span aria-hidden="true">⚠</span>
                {errors.price}
              </div>
            )}
          </div>

          <div className="form-group">
            <label>
              Number of Reviews
              <span className="tooltip" title="Total number of user reviews">ⓘ</span>
            </label>
            <input
              type="number"
              min="0"
              value={numReviews}
              onChange={(e) => setNumReviews(e.target.value)}
              placeholder="1000"
              className={errors.numReviews ? 'error' : ''}
            />
            {errors.numReviews && <div className="error-text">{errors.numReviews}</div>}
          </div>

          <div className="form-group">
            <label>
              Wishlist to Sales Conversion Factor
              <span className="tooltip" title="Estimated number of sales per review">ⓘ</span>
            </label>
            <input
              type="number"
              min="1"
              value={conversionFactor}
              onChange={(e) => setConversionFactor(Number(e.target.value))}
              className={errors.conversionFactor ? 'error' : ''}
            />
            {errors.conversionFactor && <div className="error-text">{errors.conversionFactor}</div>}
          </div>

          <div className="button-group">
            <button
              onClick={calculateRevenue}
              className={`calculate-button ${isLoading ? 'loading' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Calculating...' : 'Calculate Revenue'}
            </button>
            <button
              onClick={resetCalculator}
              className="reset-button"
              disabled={isLoading}
            >
              Reset
            </button>
            <button onClick={exportData} className="export-button" disabled={!breakdown}>Export</button>
          </div>
        </>
      )}

      {estimated !== null && !isLoading && (
        <div className="result">
          <h3>Estimated Revenue</h3>
          {breakdown && !estimated.startsWith('Error') ? (
            <>
              <p className="estimated-amount">${Number(estimated).toLocaleString()}</p>
              <div className="breakdown">
                <p>Gross Revenue: ${breakdown.grossRevenue.toLocaleString()}</p>
                <p>Platform Fee: -${breakdown.platformRevenue.toLocaleString()}</p>
                <p>Refund Loss: -${breakdown.refundLoss.toLocaleString()}</p>
                <p>Regional Pricing Impact: -${breakdown.regionalImpact.toLocaleString()}</p>
              </div>
              {chartData && (
                <div className="chart-container">
                  <Doughnut 
                    ref={chartRef}
                    data={chartData} 
                    options={chartOptions} 
                  />
                </div>
              )}
            </>
          ) : (
            <p className="error-message">{estimated}</p>
          )}
          <p className="disclaimer">
            *This is a rough estimate based on industry averages. Actual revenue may vary.
          </p>
        </div>
      )}

      {history.length > 0 && (
        <div className="history-section">
          <h3>Recent Calculations</h3>
          <div className="history-list">
            {history.map((entry, index) => (
              <div key={entry.timestamp} className="history-item">
                <span>{new Date(entry.timestamp).toLocaleDateString()}</span>
                <span>${entry.result.finalRevenue.toLocaleString()}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(GameRevenueCalculator);