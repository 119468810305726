import React from 'react';
import PropTypes from 'prop-types';

const LoadingSkeleton = ({ lines = 3, showCircle = true }) => (
  <div className="skeleton-container" role="alert" aria-busy="true" aria-label="Loading content">
    {[...Array(lines)].map((_, i) => (
      <div 
        key={i}
        className="skeleton-line"
        style={{ width: `${Math.random() * 20 + 80}%` }}
      />
    ))}
    {showCircle && <div className="skeleton-circle" />}
  </div>
);

LoadingSkeleton.propTypes = {
  lines: PropTypes.number,
  showCircle: PropTypes.bool
};

export default LoadingSkeleton;