import React from 'react';
import './App.css';
import GameRevenueCalculator from './components/GameRevenueCalculator';

function App() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="app">
      {/* Header */}
      <header className="header">
        <h1>Game Revenue Calculator</h1>
        <p>Estimate game revenue using public marketplace data</p>
      </header>

      {/* Ad Space - Top */}
      <div className="ad-space">
        <div className="ad-placeholder">
          Advertisement Space
        </div>
      </div>

      {/* Main Calculator */}
      <main>
        <GameRevenueCalculator />
      </main>

      {/* Ad Space - Bottom */}
      <div className="ad-space">
        <div className="ad-placeholder">
          Advertisement Space
        </div>
      </div>

      {/* Footer */}
      <footer className="footer">
        <p>© {currentYear} GameRevenue Calculator. Not affiliated with any game marketplace.</p>
        <p>All calculations are estimates based on public data.</p>
      </footer>
    </div>
  );
}

export default App;